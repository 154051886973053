// @ts-check
import { z } from 'zod';

const envEnum = z.enum([
  'production',
  'test',
  'preview',
  'sandbox',
  'staging',
  'development',
]);

/**
 * Specify your server-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 */
export const serverSchema = z.object({
  DATABASE_URL: z.string().url(),
  NODE_ENV: z.enum(['development', 'test', 'production']),
  // NODE_ENV being used by numerous third party packages, it is quite unsafe to override. BP_ENV adds granularity
  BP_ENV: envEnum,
  // Git commit sha. On dev, use a static value
  GIT_COMMIT_SHA: z.string().optional(),
  // Next auth
  NEXTAUTH_SECRET: z.string(),
  NEXTAUTH_URL: z.string().url().optional(),
  // Email (and nextauth email)
  NEXTAUTH_EMAIL_HOST: z.string(),
  NEXTAUTH_EMAIL_PORT: z.string(),
  NEXTAUTH_EMAIL_AUTH_USER: z.string(),
  NEXTAUTH_EMAIL_AUTH_PASS: z.string(),
  NEXTAUTH_EMAIL_FROM: z.string(),
  EMAIL_INBOUND_PARSE_POSTMARK_DOMAIN: z.string().optional(),
  // Qstash
  QSTASH_CURRENT_SIGNING_KEY: z.string(),
  QSTASH_NEXT_SIGNING_KEY: z.string(),
  QSTASH_CALLBACK_BASE_URL: z.string().optional(),
  QSTASH_TOKEN: z.string(),
  // Ably
  ABLY_PRIVATE_KEY: z.string(),
  USER_CREATION_CC_TO: z.string().optional(),
  // S3 (file uploads)
  S3_AWS_ACCESS_KEY_ID: z.string(),
  S3_AWS_SECRET_ACCESS_KEY: z.string(),
  AWS_S3_REGION: z.string(),
  AWS_S3_BUCKET_NAME: z.string(),
  // OpenAI
  OPENAI_API_KEY: z.string(),
  // Freshdesk
  FRESHDESK_WIDGET_SECRET_KEY: z.string().optional(),
  // Vercel edge config
  EDGE_CONFIG: z.string(),
  EDGE_CONFIG_ID: z.string(),
  VERCEL_API_TOKEN: z.string(),
  // Placekey
  PLACE_KEY_API_KEY: z.string(),
  PLACE_KEY_URL: z.string(),
  // Passwords / API Keys storage
  AWS_KMS_KEY_ID: z.string(),
  AWS_KMS_ACCESS_KEY: z.string(),
  AWS_KMS_SECRET_ACCESS_KEY: z.string(),
  // This is full url this app is accessible at, with scheme. (e.g. http://app.biggerpicture.co, http://localhost:3000, etc.)
  APP_URL_WITH_SCHEME: z.string().url().optional(),
  // Postmark Inbound server API token
  POSTMARK_SERVER_API_TOKEN: z.string().optional(),
  // E2open api endpoint
  E2OPEN_API_ENDPOINT: z.string().optional(),
});

/**
 * Client side env values.
 * These might be derived from serverSchema, in that case their zod type should be the same as their server counterpart.
 */
export const clientSchema = z.object({
  NEXT_PUBLIC_AWS_S3_REGION: z.string(),
  NEXT_PUBLIC_AWS_S3_BUCKET_NAME: z.string(),
  NEXT_PUBLIC_GIT_COMMIT_SHA: z.string().optional(),
  NEXT_PUBLIC_BP_ENV: envEnum,
  NEXT_PUBLIC_APP_URL_WITH_SCHEME: z.string().url().optional(),
  NEXT_PUBLIC_DATADOG_APPLICATION_ID: z.string().optional(),
  NEXT_PUBLIC_DATADOG_CLIENT_TOKEN: z.string().optional(),
  NEXT_PUBLIC_DATADOG_SESSION_REPLAY_ENABLED: z.string().optional(),
  NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: z.string(),
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA: z.string().optional(),
  // TODO remove once on railway
  NEXT_PUBLIC_VERCEL_URL: z.string().optional(),
});

/**
 * You can't destruct `process.env` as a regular object, so you have to do
 * it manually here. This is because Next.js evaluates this at build time,
 * and only used environment variables are included in the build.
 * @type {{ [k in keyof z.infer<typeof clientSchema>]: z.infer<typeof clientSchema>[k] | undefined }}
 */
export const clientEnv = {
  NEXT_PUBLIC_GIT_COMMIT_SHA: process.env.NEXT_PUBLIC_GIT_COMMIT_SHA,
  NEXT_PUBLIC_APP_URL_WITH_SCHEME: process.env.NEXT_PUBLIC_APP_URL_WITH_SCHEME,
  NEXT_PUBLIC_AWS_S3_REGION: process.env.NEXT_PUBLIC_AWS_S3_REGION,
  NEXT_PUBLIC_AWS_S3_BUCKET_NAME: process.env.NEXT_PUBLIC_AWS_S3_BUCKET_NAME,
  // @ts-expect-error BP_ENV is an envEnum
  NEXT_PUBLIC_BP_ENV: process.env.NEXT_PUBLIC_BP_ENV,
  NEXT_PUBLIC_DATADOG_SESSION_REPLAY_ENABLED:
    process.env.NEXT_PUBLIC_DATADOG_SESSION_REPLAY_ENABLED,
  NEXT_PUBLIC_DATADOG_APPLICATION_ID:
    process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
  NEXT_PUBLIC_DATADOG_CLIENT_TOKEN:
    process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  // TODO remove once on railway
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA:
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
};
