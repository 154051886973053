import opentelemetry from '@opentelemetry/api';
import pino from 'pino';

export const logger = pino({
  formatters: {
    level: (label) => {
      return {
        level: label,
      };
    },
    log(object) {
      const span = opentelemetry.trace.getSpan(opentelemetry.context.active());
      if (span) {
        const { spanId, traceId } = span.spanContext();
        const traceIdEnd = traceId.slice(traceId.length / 2);
        object['dd.trace_id'] = BigInt(`0x${traceIdEnd}`).toString();
        object['dd.span_id'] = BigInt(`0x${spanId}`).toString();
      }
      return object;
    },
  },
});
