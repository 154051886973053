import { Grid } from '@mantine/core';
import classNames from 'classnames';
import { env } from 'env/client.mjs';
import backgroundImage from 'images/background.png';
import Image from 'next/image';
import Script from 'next/script';

import { ErrorBoundary } from 'components/error/ErrorBoundary';

import classes from './AuthLayout.module.css';

export const AuthLayout = ({ children }: React.PropsWithChildren) => (
  <>
    {env.NEXT_PUBLIC_BP_ENV === 'production' && (
      <Script id="FreshworksWidget">
        {`window.fwSettings={'widget_id':150000002587};
!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
`}
      </Script>
    )}
    <Grid className={classes.fullHeight} gutter={0}>
      <Grid.Col span={{ base: 9, lg: 6 }}>
        <Grid
          justify="center"
          className={classNames([classes.fullHeight, classes.center])}
        >
          <Grid.Col span={{ base: 10, xl: 8 }}>
            <ErrorBoundary>{children}</ErrorBoundary>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col span={{ base: 3, lg: 6 }} style={{ position: 'relative' }}>
        <Image
          className={classes.background}
          src={backgroundImage}
          alt="background image"
          fill
          priority
        ></Image>
      </Grid.Col>
    </Grid>
  </>
);
