import { Anchor } from '@mantine/core';
import { env } from 'env/client.mjs';

export const SupportLink = () => {
  return (
    env.NEXT_PUBLIC_BP_ENV === 'production' && (
      <Anchor
        href="#"
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          const FreshworksWidget = window.FreshworksWidget;
          FreshworksWidget?.('open', 'ticketForm');
        }}
      >
        Contact support
      </Anchor>
    )
  );
};
