import React from 'react';
import { Button, Group, Text, Title } from '@mantine/core';
import router from 'next/router';

import { logger } from 'utils/logger';
import { navigateTo } from 'utils/navigation';

export class ErrorBoundary extends React.Component<
  React.PropsWithChildren,
  { hasError: boolean }
> {
  constructor(props: React.PropsWithChildren) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    logger.error({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Group py={100} gap="md" style={{ flexDirection: 'column' }}>
          <Title>500</Title>
          <Text color="dimmed" size="lg">
            Sorry, something went wrong.
          </Text>
          <Button
            onClick={() => {
              navigateTo('/', router);
            }}
          >
            Back Home
          </Button>
        </Group>
      );
    }

    return this.props.children;
  }
}
