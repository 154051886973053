import { Anchor } from '@mantine/core';
import type { NotificationType } from '@prisma/client';
import Link from 'next/link';

import { toast } from 'components/utils/toast';

const defaultAppointmentMessageHandler = ({
  message,
  itemId,
}: {
  itemId: string;
  message: string;
}) => {
  toast.warning(
    <Anchor component={Link} href={`/appointments/${itemId}`}>
      View order
    </Anchor>,
    message,
  );
};

export const messageHandlers: {
  [key in NotificationType]: ({
    itemId,
    message,
  }: {
    itemId: string;
    message: string;
  }) => void;
} = {
  AppointmentCanceled: defaultAppointmentMessageHandler,
  AppointmentConfirmed: defaultAppointmentMessageHandler,
  NewAppointmentComment: defaultAppointmentMessageHandler,
  AppointmentCreated: defaultAppointmentMessageHandler,
  BulkNewComment: defaultAppointmentMessageHandler,
  BulkRequestSent: defaultAppointmentMessageHandler,
  AppointmentPending: defaultAppointmentMessageHandler,
  AppointmentRefused: defaultAppointmentMessageHandler,
  AppointmentDeclined: defaultAppointmentMessageHandler,
  AppointmentScheduled: defaultAppointmentMessageHandler,
  AppointmentReadyToSchedule: defaultAppointmentMessageHandler,
  AppointmentReadyForPickup: defaultAppointmentMessageHandler,
  AppointmentRescheduled: defaultAppointmentMessageHandler,
  AppointmentOnHold: defaultAppointmentMessageHandler,
  AppointmentReleased: defaultAppointmentMessageHandler,
  AppointmentFilesAdded: defaultAppointmentMessageHandler,
  AppointmentDirectionChanged: defaultAppointmentMessageHandler,
  AppointmentMarkedConfirmed: defaultAppointmentMessageHandler,
  DetentionIn15Minutes: defaultAppointmentMessageHandler,
  DetentionIn30Minutes: defaultAppointmentMessageHandler,
  DetentionOccurred: defaultAppointmentMessageHandler,
  DetentionStillOccurring: defaultAppointmentMessageHandler,
  SelfCheckedIn: defaultAppointmentMessageHandler,
  ETAConfirmation: defaultAppointmentMessageHandler,
  ShipmentInfeasible: defaultAppointmentMessageHandler,
  EmailNotSent: defaultAppointmentMessageHandler,
};
